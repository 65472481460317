export function filterContentBySlug(content, slug) {
  const contentBySlug = content.filter((elem) => elem.attributes.Slug == slug);

  if (contentBySlug.length && contentBySlug[0] && contentBySlug[0].attributes)
    return contentBySlug[0].attributes;

  return null;
}

export function filterContentByType(content, typeSlug) {
  const contentByType = content.filter(
    (elem) => elem.attributes.gsa_content_type.data.attributes.Slug == typeSlug
  );

  return contentByType;
}
