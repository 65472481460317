import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

//import Modal from '../Modal/Modal.js';
import WalletContainer from '../../wallet/WalletContainer.tsx';

import './Menu.scss';

import { FaTimes, FaBars } from 'react-icons/fa';
import { IoMdWallet } from 'react-icons/io';

const Menu = () => {
  const location = useLocation();
  const pathname = location.pathname ?? '';

  const linkClasses = {
    '/': '',
    '/about': '',
    '/news': '',
    '/gallery': '',
    '/wallet': '',
  };

  linkClasses[pathname] = 'active';
  const [isMobile, setIsMobile] = useState(false);
  //const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className='menu'>
      <ul
        className={isMobile ? 'nav-links-mobile' : 'nav-links'}
        onClick={() => setIsMobile(false)}
      >
        <li className={linkClasses['/'] + ' homebutton'}>
          <NavLink to='/'>Home</NavLink>
        </li>
        <li className={linkClasses['/about'] + ' aboutbutton'}>
          <NavLink to='/about'>About</NavLink>
        </li>
        <li className={linkClasses['/news'] + ' newsbutton'}>
          <Link to='/news'>News</Link>
        </li>
        <li className={linkClasses['/gallery'] + ' gallerybutton'}>
          <Link to='/gallery'>Gallery</Link>
        </li>
        <li className={linkClasses['/showroom'] + ' showroombutton'}>
          <Link to='/showroom'>Showroom</Link>
        </li>
      </ul>
      <div className='wallet-button'>
        <Link to='/wallet'>
          <IoMdWallet />
          <WalletContainer />
        </Link>
      </div>

      <button
        className={
          isMobile ? 'mobile-menu-icons mobile-menu-x' : 'mobile-menu-icons'
        }
        onClick={() => setIsMobile(!isMobile)}
      >
        {isMobile ? <FaTimes /> : <FaBars />}
      </button>
    </div>
  );
};

export default Menu;
