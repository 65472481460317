import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import contentService from '../services/contentService.js';
import { filterContentBySlug } from '../utils/content.js';

import Header from '../components/layout/Header/Header.js';
import Footer from '../components/layout/Footer/Footer.js';

const Home = lazy(() => import('../components/pages/Home/Home'));
const About = lazy(() => import('../components/pages/About/About'));
const News = lazy(() => import('../components/pages/News/News'));
const Login = lazy(() => import('../components/pages/Home/Home'));
const NotFound = lazy(() => import('../components/pages/NotFound/NotFound'));
const Gallery = lazy(() => import('../components/pages/Gallery/Gallery'));
const NewsPage = lazy(() => import('../components/pages/NewsPage/NewsPage'));
const Showroom = lazy(() => import('../components/pages/Showroom/Showroom'));

const AppRouter = () => {
  const [content, setContent] = useState([]);
  const [headerMaincontent, setHeaderMaincontent] = useState({});
  const [headerRightcontent, setHeaderRightcontent] = useState({});

  useEffect(() => {
    contentService
      .getContent()
      .then((contentResponse) => {
        setContent(contentResponse.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    //console.log('content is changed to:', content);
    setHeaderMaincontent(filterContentBySlug(content, 'header-home'));
    setHeaderRightcontent(filterContentBySlug(content, 'header-play-now'));
  }, [content]);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Header
          headerMaincontent={headerMaincontent}
          headerRightcontent={headerRightcontent}
        ></Header>
        <Routes>
          <Route index element={<Home content={content} />} />
          <Route path='about' element={<About />} />
          <Route path='news' element={<News />} />
          <Route path='showroom' element={<Showroom />} />
          <Route path='login' element={<Login />} />
          <Route path='gallery' element={<Gallery />} />
          <Route path='wallet' element={<Home content={content} />} />
          <Route path='*' element={<NotFound />} />
          <Route path='/news/:slug' element={<NewsPage />} />
        </Routes>
        <Footer></Footer>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
