import { FaDownload } from 'react-icons/fa';

const HeaderMain = ({ content }) => {
  //console.log('HeaderMain content:', content);
  return (
    <div className='banner-text'>
      <h1 className='text-uppercase'>
        <span className='color'>
          {content && content.Title ? content.Title : 'Grand Sport '}
        </span>
        Auto
      </h1>
      <h3>
        {content && content.Subtitle
          ? content.Subtitle
          : 'Welcome to GSA 10000 NFT super auto sale '}
      </h3>
      <p className='text-uppercase banner-text-subtext'>
        {content && content.Content
          ? content.Content
          : 'A unique digital evolution collection on Solana Blockchain Each NFT represents a sports automobile in a full blockchain racing ecosystem game'}
      </p>
      <a
        href={content && content.ActionLink ? content.ActionLink : ''}
        className='yellow-button'
      >
        <FaDownload />{' '}
        {content && content.ActionText ? content.ActionText : 'Download Game'}
      </a>
    </div>
  );
};

export default HeaderMain;
