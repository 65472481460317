import { constructOptions, request } from './requester';

export const get = async (url, idToken) => {
  return await request(url, constructOptions('get', idToken));
};

export const post = async (url, data, idToken) => {
  return await request(url, constructOptions('post', idToken, data));
};

export const put = async (url, data, idToken) => {
  return await request(url, constructOptions('put', idToken, data));
};

export const del = async (url, idToken) => {
  return await request(url, constructOptions('delete', idToken));
};
