//import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

//import headerInfoService from '../../../services/headerInfoService.js';

import logo from '../../../assets/static/images/logo-dark-187x32.png';

import { IoIosArrowForward } from 'react-icons/io';
import { BsDiscord, BsTwitter, BsMedium } from 'react-icons/bs';

import Menu from '../Menu/Menu.js';
import HeaderMain from './HeaderMain/HeaderMain.js';

import './Header.scss';

const Header = ({ headerMaincontent, headerRightcontent }) => {
  const location = useLocation();
  const pathname = location.pathname ?? '';
  const addonclassName =
    pathname === '/gallery'
      ? 'header-gallery'
      : pathname === '/'
      ? 'header-home'
      : pathname === '/news'
      ? 'header-page'
      : pathname === '/about'
      ? 'header-page'
      : 'header-gallery';

  let pageH1 = pathname.substring(1);

  //console.log('headerMaincontent prop:', headerMaincontent);
  //console.log('headerRightcontent prop:', headerRightcontent);

  //const idToken = null; // add authentication
  //const [headerInfo, setHeaderInfo] = useState([]);

  return (
    <header className={`header ${addonclassName}`}>
      <div id='topbar'>
        <div className='container'>
          <div className='row'>
            <div id='logo' className='col-sm-4 col-xs-6 '>
              <Link to='/'>
                <img src={logo} alt='Logo' />
              </Link>
              <div className='header-social-icons'>
                <a href='https://discord.gg/2ASFFnbUS8'>
                  <BsDiscord />
                </a>
                <a href='https://twitter.com/GSAuto_NFT'>
                  <BsTwitter />
                </a>
                <a href='https://medium.com/@GSAuto_NFT'>
                  <BsMedium />
                </a>
              </div>
            </div>

            <div className='col-sm-8 col-xs-6 topbar-address'>
              <Menu />
            </div>
          </div>
        </div>
      </div>

      <div id='banner' className='module parallax parallax-1'>
        <div className='container'>
          <div className='row proba'>
            <div className='col-md-8 col-sm-12 text-left col-pull-middle'>
              <HeaderMain content={headerMaincontent} />
            </div>

            <div className='col-md-4 col-sm-12 col-pull-down'>
              <div className='form-container text-center'>
                <div className='form-head'>
                  <h3>REAL TIME MULTIPLAYER</h3>
                  <hr />
                  <p>
                    <b>
                      Play with other people online up to 25 massive race events
                    </b>
                  </p>
                  <p>
                    Fun casual arcade racing game with unique cars with special
                    abilities and environment changes.
                  </p>
                </div>
                <div className='expMessage'></div>
                <form id='signUp-form'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <button
                        type='submit'
                        value='submit'
                        className='btn btn-default btn-block btn-submit text-uppercase'
                      >
                        Play Now
                      </button>
                    </div>
                  </div>

                  <input type='hidden' name='action' value='submitform' />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='banner2' className='simplepage-header'>
        <div className='container'>
          <div className='row row-banner2'>
            <h1>{pageH1}</h1>
            <span>
              <Link to=''>Home</Link>
              <IoIosArrowForward />
              <Link to=''>{pageH1}</Link>
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
