const apiBaseUrl = 'http://grandsportauto.io:1337/api/';
const apiGame = 'http://localhost:8080/api/';

const apiRoutes = {
  /* content */
  getContent: () => `${apiBaseUrl}gsa-contents?populate=*`,
  getContentById: (id) => `${apiBaseUrl}gsa-contents/${id}?populate=*`,
  /* news */
  getNews: () => `${apiBaseUrl}gsa-news?populate=*`,
  getNewsById: (id) => `${apiBaseUrl}gsa-news/${id}?populate=*`,
  getNewsBySlug: (slug) =>
    `${apiBaseUrl}gsa-news/?filters[Slug][$eq]=${slug}&populate=*`,

  /*get gallery */
  getGallery: () => `${apiBaseUrl}gsa-galleries?populate=*`,

  /* faq */
  getFaq: () => `${apiBaseUrl}faqs`,

  /* timeline items */
  getTimelieneItem: () => `${apiBaseUrl}timelines`,

  /*header home */
  getHeaderInfo: (id) => `${apiBaseUrl}gsa-contents/${id}?populate=*`,

  /*subscribe to newsletter*/
  addSubscriber: () => `${apiBaseUrl}newsletters`,

  /*get twitter posts */
  getTwitterPosts: () => `${apiGame}/twitter`,
};

export default apiRoutes;
