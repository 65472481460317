import { Link } from 'react-router-dom';

import { BsDiscord, BsTwitter, BsMedium, BsYoutube } from 'react-icons/bs';

import './Footer.scss';

const Footer = () => {
  return (
    <div id='bottom'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 col-sm-6'>
            <div className='about-us-box'>
              <h1 className='bottom-heading'>
                Tournaments<span className='bottom-heading-line'></span>
              </h1>
              <p className='about-text'>
                Weekly tournaments with prizes from sponsors.
              </p>
              <h1 className='bottom-heading'>
                Teams<span className='bottom-heading-line'></span>
              </h1>
              <p className='about-text'>
                Play with friends against other teams in a fast paced action
                race. Reck or get recked.
              </p>
            </div>
          </div>
          <div className='col-md-3 col-sm-3'>
            <div className='services-box'>
              <h1 className='bottom-heading'>
                Quick Links<span className='bottom-heading-line'></span>
              </h1>
              <ul className='services-list list-unstyled'>
                <li>
                  <Link to=''>Daily / Weekly challenges</Link>
                </li>
                <li>
                  <Link to=''>Classic race with powerups</Link>
                </li>
                <li>
                  <Link to=''>Derby - last man standing</Link>
                </li>
                <li>
                  <Link to=''>Team Derby</Link>
                </li>
                <li>
                  <Link to=''>Team capture the flag</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-md-3 col-sm-3'>
            <div className='get-intouch-box'>
              <h1 className='bottom-heading'>
                Get in touch<span className='bottom-heading-line'></span>
              </h1>
              <div className='media'>
                <div className='media-left'>
                  <i className='fa fa-home'></i>
                </div>
                <div className='media-body'>
                  <span className='socials'>
                    <a href='https://discord.gg/2ASFFnbUS8'>
                      <BsDiscord />
                    </a>
                  </span>
                  <span className='socials'>
                    <a href='https://twitter.com/GSAuto_NFT'>
                      <BsTwitter />
                    </a>
                  </span>
                  <span className='socials'>
                    <a href='https://medium.com/@GSAuto_NFT'>
                      <BsMedium />
                    </a>
                  </span>
                  <span className='socials'>
                    <a href='https://www.youtube.com/channel/UCVmbAmjiGnpW9ZnhoxJv2GQ'>
                      <BsYoutube />
                    </a>
                  </span>
                </div>
              </div>
              <div className='media'>
                <div className='media-left'>
                  <i className='fa fa-phone'></i>
                </div>
                <div className='media-body'>
                  <p>
                    <a href='tel:8001234567'>(800) 123 4567</a>
                  </p>
                </div>
              </div>
              <div className='media'>
                <div className='media-left'>
                  <i className='fa fa-envelope'></i>
                </div>
                <div className='media-body'>
                  <a href='mailto:games@info.com'>games@info.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copyright'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <p className='copyright-text'>
                Copyright 2017 - Car Games. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
