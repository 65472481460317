import * as requester from '../api/restActions.js';
import apiRoutes from '../api/config.js';

const getContent = (idToken = null) => {
  return requester.get(apiRoutes.getContent(), idToken);
};

const getContentById = (id, idToken = null) => {
  return requester.get(apiRoutes.getContentById(id), idToken);
};

const contentService = {
  getContent,
  getContentById,
};

export default contentService;
