export const request = async (url, options) => {
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
};

export const constructOptions = (method = 'get', idToken, body) => {
  const options = {
    method,
    headers: {},
  };

  if (idToken) {
    options.headers['X-Authorization'] = idToken;
  }

  if (body) {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(body);
  }

  return options;
};
