import AppRouter from './routers/AppRouter';

function App() {
  return (
    <div className='App'>
      <AppRouter />
    </div>
  );
}

export default App;
